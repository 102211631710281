<template>
  <div id="cmpResumenDia">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Resumen {{ fecha_inicial == fecha_final ? 'del día' : 'de los días' }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-sm-4">
            <div class="row">
              <div class="col-sm-5 col-6">
                <label>Fecha inicial</label>
                <Datepicker
                  ref="fecha_inicial"
                  v-model="fecha_inicial"
                  :class="'form-control bg-white'"
                  :inputFormat="'dd/MM/yyyy'"
                />
              </div>
              <div class="col-sm-5 col-6">
                <label>Fecha final</label>
                <Datepicker
                  ref="fecha_final"
                  v-model="fecha_final"
                  class="form-control bg-white"
                  :inputFormat="'dd/MM/yyyy'"
                />
              </div>
              <div class="col-sm-2 mt-4">
                <button class="btn btn-success" @click="refrescarAbonos()" title="Filtrar">
                  <i class="mdi mdi-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-4">
            <div class="input-group">
              <span class="input-group-text">
                <i class="mdi mdi-account"></i>
                Usuario
              </span>
              <select v-model="id_usuario" class="form-select" @change="refrescarAbonos()">
                <option :value="null">Todos</option>
                <option :value="-1">Mi servicio de internet</option>
                <option
                  :value="usuario.id"
                  v-for="usuario in usuarios"
                  :key="usuario.id"
                >
                  {{ usuario.nombre }} {{ usuario.apellido_paterno }} {{ usuario.apellido_materno }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 mt-4">
            <div class="input-group">
              <span class="input-group-text">
                <i class="mdi mdi-cash"></i>
                Metodo pago
              </span>
              <select v-model="id_metodo_pago" class="form-select" @change="refrescarAbonos()">
                <option :value="null">Todos</option>
                <option
                  :value="metodo.id"
                  v-for="metodo in metodos_pago"
                  :key="metodo.id"
                >
                  {{ metodo.nombre }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3 mt-4" v-if="id_metodo_pago != 1 && id_metodo_pago != null">
            <div class="input-group">
              <span class="input-group-text">
                <i class="mdi mdi-bank"></i>
                Banco
              </span>
              <select v-model="id_banco" class="form-select" @change="refrescarAbonos()">
                <option :value="null">Todos</option>
                <option
                  :value="banco.id"
                  v-for="banco in bancos"
                  :key="banco.id"
                >
                  {{ banco.nombre }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-md-9 pt-2 mb-3">
            <div class="form-check font-size-16" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="nota"
                v-model="columnas.nota"
                @change="actualizarValoresColumnasEnLocalStorage()"
              />
              <label class="form-check-label" for="nota">Nota</label>
            </div>
            &nbsp; 
            <div class="form-check font-size-16" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="idOperacion"
                v-model="columnas.id_operacion"
                @change="actualizarValoresColumnasEnLocalStorage()"
              />
              <label class="form-check-label" for="idOperacion">ID de opecación</label>
            </div>
          </div>
          <div class="col-md-3 text-right" v-if="abonos.length > 0" style="padding:10px">
            <span class="badge bg-secondary bx-pull-right bg-gradient font-size-12">
              {{ nAbonosTotales }} pagos registrados
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              class="table-responsive"
              style="height: 400px;"
              v-if="abonos.length > 0"
            >
              <table
                class="table table-bordered table-hover table-striped align-middle table-nowrap"
                style="min-width: 1250px;"
                id="tabla-abonos"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Cliente</th>
                    <th>Fecha y hora</th>
                    <th style="width: 130px">Tipo</th>
                    <th>Facturas pagadas</th>
                    <th>Importe</th>
                    <th v-show="columnas.nota">Nota</th>
                    <th v-show="columnas.id_operacion">ID de operación</th>
                    <th style="min-width: 200px">Usuario que cobró</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="abono in abonos">
                    <tr v-if="true" :key="abono.id">
                      <td class="fw-semibold">{{ abono.id }}</td>
                      <td>
                        <h5 class="font-size-14 text-truncate">
                          <router-link
                            :to="{
                              name: 'tableroCliente',
                              params: { id_cliente: abono.id_cliente }
                            }"
                            :title="abono.nombre_cliente"
                          >
                            {{ abono.nombre_cliente }}
                          </router-link>
                        </h5>
                      </td>
                      <td>{{ abono.fecha }}</td>
                      <td>
                        <span v-show="abono.id_metodo_pago == 1">
                          Efectivo
                        </span>
                        <span
                          v-show="abono.id_metodo_pago == 2"
                          class="text-info"
                        >
                          Depósito
                        </span>
                        <span
                          v-show="abono.id_metodo_pago == 3"
                          class="text-warning"
                        >
                          Transferencia
                        </span>
                        <span
                          v-show="abono.id_metodo_pago == 4"
                          class="text-success"
                        >
                          PayPal
                        </span>
                        <span
                          v-show="abono.id_metodo_pago == 102"
                          class="text-primary"
                        >
                          Mercado Pago
                        </span>

                        <button
                          @click="palancaMostrarBanco(abono)"
                          v-show="abono.id_metodo_pago == 2"
                          class="btn btn-light btn-sm"
                        >
                          <i
                            class="mdi"
                            :class="{
                              'mdi-chevron-down': !abono.mostrar_banco, 
                              'mdi-chevron-up': abono.mostrar_banco
                            }"
                          ></i>
                        </button>
                      </td>
                      <td>
                        <router-link
                          :to="{ name: 'factura', params: { id: factura.id } }"
                          v-for="(factura,index) in abono.facturas"
                          :key="index"
                          class="me-1"
                        >
                          {{ factura.ns }}
                          <br v-if="((index+1) % 4) == 0">
                        </router-link>
                      </td>
                      <td class="text-right fw-semibold">
                        {{ formatoMoneda(abono.importe) }}
                      </td>
                      <td v-show="columnas.nota">
                        <p class="text-truncate font-size-14" :title="abono.nota">
                          {{ abono.nota }}
                        </p>
                      </td>
                      <td v-show="columnas.id_operacion" class="fw-semibold">
                        {{ abono.id_operacion }}
                      </td>
                      <td>
                        {{ abono.nombre_usuario }}
                      </td>
                    </tr>
                    <tr v-if="abono.mostrar_banco" :key="`banco-abono-${abono.id}`">
                      <td colspan="3"></td>
                      <td colspan="6">
                        <strong>Banco:</strong> {{ abono.nombre_banco }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="abonos.length > 0" class="table-responsive">
          <table class="table table-hover table-bordered" style="width: 300px">
            <tbody>
              <tr>
                <td class="text-right"><label>Total de efectivo</label></td>
                <td class="text-right">{{ formatoMoneda(totalEfectivo) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total de depósitos</label></td>
                <td class="text-right">{{ formatoMoneda(totalDepositos) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total</label></td>
                <td class="text-right">{{ formatoMoneda(total) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-md-12" style="height: 300px;" v-show="abonos.length == 0">
            <div id="abonos-registrados"></div>
            No hay abonos registrados
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Componente del resumen del día
 */
 var accounting = require('accounting/accounting.js')
import AbonoSrv from '@/services/AbonoSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import MetodoPagoSrv from '@/services/MetodoPagoSrv.js'
import BancoSrv from '@/services/BancoSrv.js'
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
export default {
  name: 'CmpResumenDia',
  components: {
    Datepicker
  },
  data() {
    return {
      abonos: [],
      baseUrl: window.location.origin,
      fecha_inicial: '',
      fecha_final: '',
      totalEfectivo: 0,
      totalDepositos: 0,
      total: 0,
      nAbonosTotales: 0,
      id_usuario: null,
      id_banco: null,
      id_metodo_pago: null,
      usuarios: [],
      bancos: [],
      metodos_pago:[],
      columnas: {
        nota: true,
        id_operacion: false
      },
      interval: {
        abonos: null
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  watch: {
    fecha_inicial: function(newVar,oldVar){
      var self = this
      self.refrescarAbonos()
    },
    fecha_final: function(newVar,oldVar){
      var self = this
      self.refrescarAbonos()
    }
  },
  created: function() {
    var self = this
    
    // Carga de los bancos, metodos de pago y usuarios
    self.cargarBancos()
    self.cargarMetodosPago()
    self.cargarUsuarios()

    // Carga de la fecha del sistema
    self.fecha_inicial = new Date()
    self.fecha_final = new Date()

    // Carga inicial de los abonos
    self.refrescarAbonos()

    self.cargarValoresColumnasDeLocalStorage()
    // Intervalos para cada 10 min realizar la carga de los abonos
    self.interval.abonos = setInterval(function() {
      self.refrescarAbonos()
    }, 600000)
  },
  beforeUnmount() {
    // Destrucción de los interval lanzados
    clearInterval(this.interval.abonos)
  },
  mounted: function(){
    iu.spinner.mostrar('#tabla-abonos')
    iu.spinner.mostrar('#abonos-registrados')
  },

  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.cmpResumenDia.columnas', JSON.stringify(self.columnas))
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.cmpResumenDia.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },
    calcularTotales: function() {
      var self = this
      self.total = 0
      self.totalEfectivo = 0
      self.totalDepositos = 0

      self.abonos.forEach(function(abono, index) {
        self.total += abono.importe

        if (abono.id_metodo_pago == 1) self.totalEfectivo += abono.importe
        else self.totalDepositos += abono.importe
      })
    },

    cargarBancos:function() {
      var self = this

      BancoSrv.bancosJSON().then(response => {
        self.bancos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los bancos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMetodosPago: function() {
      var self = this

      MetodoPagoSrv.metodosJSON().then(response => {
        self.metodos_pago = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los metodos de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarUsuarios: function() {
      var self = this

      let params = {
        todos: true
      }

      UsuarioSrv.usuariosJSON(params).then(response => {
        self.usuarios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    formatoFecha(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },
    palancaMostrarBanco: function(abono) {
      if (abono.mostrar_banco) abono.mostrar_banco = false
      else abono.mostrar_banco = true
    },
    refrescarAbonos: function() {
      var self = this
      
      let params = {
        fecha_inicial : self.formatoFecha(self.fecha_inicial),
        fecha_final : self.formatoFecha(self.fecha_final),
        id_usuario: self.id_usuario,
        id_metodo_pago: self.id_metodo_pago,
        pagina: 1,
        por_pagina: 10,
        cancelados: false,
        todos: true,
        con_facturas_abonadas:false
      }

      if(self.id_metodo_pago != 1 && self.id_metodo_pago != null){
        Object.assign(params,{
          id_banco: self.id_banco
        })
      }

      iu.spinner.mostrar('#tabla-abonos')
      iu.spinner.mostrar('#abonos-registrados')

      AbonoSrv.abonosJSON(params).then(response => {
        let res = response.data
        var abonos = res.abonos
        self.nAbonosTotales = res.total
        // Carga de bandera para mostrar el banco donde fue depositado
        abonos.forEach(function(abono, index) {
          abono.mostrar_banco = false
        })

        self.abonos = abonos

        self.calcularTotales()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los abonos del dia'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#tabla-abonos')
        iu.spinner.ocultar('#abonos-registrados')
      })
    }
  },
}
</script>

<style scoped>
.text-truncate {
  width: 180px;
}
</style>